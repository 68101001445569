export const environment = {

  //  apiBaseUrl: 'https://devcmbfsv1api.xenovex.com',  //dev
  //   imageBaseUrl: 'https://devcmbfsv1images.xenovex.com/',

  // apiBaseUrl: 'http://localhost:7000',     //live
  // apiBaseUrl: 'https://cmbfsv1api.xenovex.com',     //live
  // imageBaseUrl: 'https://cmbfsv1images.xenovex.com/',

  //apiBaseUrl: 'https://devcmbfsapi.tnega.org',     //dev.org
  apiBaseUrl: 'https://hmsapi.xenovex.com',
  imageBaseUrl: 'https://devcmbfsimages.tnega.org/',
  // signalrUrl: 'http://localhost:5255/notifyHub',
  signalrUrl: 'http://cmbfssignalr.tnega.org:8000/notifyhub',

  // apiBaseUrl: 'https://cmbfsapi.tnega.org',     //live.org
  // imageBaseUrl: 'https://cmbfsimages.tnega.org/',

  tokenEndPoint: '/api/token',
  production: false,
  environment: 'Local',
  showEnvironment: true,
};
