import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupComponent } from './popup.component';
import {MatDialog} from '@angular/material/dialog';
import { DashboardService } from 'src/app/services/dashboard.service';


@Component({
  selector: 'app-holidaycalendar',
  templateUrl: './holidaycalendar.component.html',
  styleUrls: ['./holidaycalendar.component.scss']
})
export class HolidaycalendarComponent implements OnInit {
  selected: Date | null;
  holidayCalendar: any;
  myFilter: any;
    
  invalid: [
    {
        recurring: {
            repeat: 'weekly',
            weekDays: 'SA,SU'
        }
    }, {
        start: '2022-10-19T00:00',
        end: '2022-10-20T00:00'
        title: 'Team offsite',
        allDay: true
    }, {
        start: '2022-10-26T00:00',
        end: '2022-10-27T00:00',
        title: 'Team offsite',
        allDay: true
    }
];



  
  openDialog() {
    this.dialog.open(PopupComponent);
  } 
  
  @ViewChild('picker', { static: false }) picker;
  constructor(public dialog: MatDialog, private dashboardService: DashboardService) { }
  

  ngOnInit(): void {
    this.dashboardService.getHolidays(1, true).subscribe(res => {

      this.myFilter = (d: Date | null): boolean => {
        console.log(d)
        const year = (d || new Date()).getFullYear();
        const month = (d || new Date()).getMonth();
        const date = (d || new Date()).getDate();
      
        let isHoliday: boolean = true
      
        res?.forEach(day => {
          const calendarYear = +day.date.slice(0, 4)
          const calendarMonth = +day.date.slice(5, 7) - 1
          const calendarDate = +day.date.slice(8, 10)

          if (calendarYear === year && calendarMonth === month && calendarDate === date && day.isHoliday) {
            isHoliday = false;
          }
        })
      
        return isHoliday;
      };
    })
  }
  
  

}
